
    .el-int{
        width: 260px;
        margin-left: 20px;
    }
    .sales-content{
        padding-top: 15px;
        background-color: white;
    height: 100%;
    .sales-feature{
        display: flex;
        justify-content: space-between;
        padding: 0 0 20px 20px;
        // border-bottom: 1px solid #eaeaea;
        .feature-left{
            .el-select {
                margin-left: 10px;
            }
            .radio-group{
               ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                    background-color: #0824CE;
                    border-color: #0824CE;
                }
                ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover{
                    color: #fff;
                }
                ::v-deep.el-radio-button__inner:hover {
                    color: #0824CE;
                }
            }
        }
        .feature-right{
            .updata-time {
                margin-right: 10px;
                color: #999;
            }
        }
    }
    ::v-deep.el-table td {
        border-bottom: 8px solid #ebeef5;
    }
    ::v-deep.el-table{
        display: flex;
        flex-direction: column;
        /*height: calc(100% - 114px);*/
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }
    ::v-deep.el-table__footer-wrapper,::v-deep.el-table__header-wrapper{
        overflow: initial;
    }
    ::v-deep.el-table__body-wrapper{
       min-height:654px;
    }
    ::v-deep.el-table th{
        padding: 14px 0 ;
    }
    .sowing{
        display: flex;
        align-items: center;
        /*width: 30px;*/
        height: 30px;
        img{
            display: inline-block;
            height:30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 50%;
        }
        .sowing-name{
            max-width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .operation{
        ::v-deep.el-button--primary {
             color: #FFF;
             background-color: #0824CE;
             border-color: #0824CE;
         }
        ::v-deep.el-button--primary:focus, .el-button--primary:hover {
            background: #495df1;
            border-color: #495df1;
            color: #FFF;
        }
        ::v-deep.el-button{
            padding: 8px 13px;
            border-radius: 2px;
        }
    }
    ::v-deep.el-date-editor.el-input, ::v-deep.el-date-editor.el-input__inner{
        margin-left: 10px;
    }

}
